<template>
  <div
    v-if="variant === 'v1'"
    class="nav-left-arrow w-nav-arrow-left !tw-z-20"
    :class="{ '!tw-hidden': !canPaginateNext && !canPaginateBack }"
    :isDisabled="!canPaginateBack"
    @click="() => swiper.slidePrev()"
  >
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M16.011 3.49956C16.1418 3.6335 16.2151 3.81332 16.2151 4.00056C16.2151 4.18781 16.1418 4.36762 16.011 4.50156L8.70149 12.0001L16.011 19.4971C16.1418 19.631 16.2151 19.8108 16.2151 19.9981C16.2151 20.1853 16.1418 20.3651 16.011 20.4991C15.9474 20.5644 15.8714 20.6163 15.7874 20.6517C15.7034 20.6872 15.6132 20.7054 15.522 20.7054C15.4308 20.7054 15.3406 20.6872 15.2566 20.6517C15.1726 20.6163 15.0966 20.5644 15.033 20.4991L7.25999 12.5236C7.12347 12.3835 7.04706 12.1957 7.04706 12.0001C7.04706 11.8045 7.12347 11.6166 7.25999 11.4766L15.033 3.50106C15.0966 3.43575 15.1726 3.38383 15.2566 3.34839C15.3406 3.31294 15.4308 3.29468 15.522 3.29468C15.6132 3.29468 15.7034 3.31294 15.7874 3.34839C15.8714 3.38383 15.9474 3.43575 16.011 3.50106V3.49956Z"
        fill="#000"
      />
    </svg>
  </div>
  <div
    v-if="variant === 'v1'"
    class="nav-right-arrow w-nav-arrow-right !tw-z-20"
    :class="{ '!tw-hidden': !canPaginateNext && !canPaginateBack }"
    :isDisabled="!canPaginateNext"
    @click="() => swiper.slideNext()"
  >
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.98901 3.49956C7.85817 3.6335 7.78491 3.81332 7.78491 4.00056C7.78491 4.18781 7.85817 4.36762 7.98901 4.50156L15.2985 12.0001L7.98901 19.4971C7.85817 19.631 7.78491 19.8108 7.78491 19.9981C7.78491 20.1853 7.85817 20.3651 7.98901 20.4991C8.0526 20.5644 8.12864 20.6163 8.21263 20.6517C8.29661 20.6872 8.38685 20.7054 8.47801 20.7054C8.56917 20.7054 8.65941 20.6872 8.74339 20.6517C8.82738 20.6163 8.90342 20.5644 8.96701 20.4991L16.74 12.5236C16.8765 12.3835 16.9529 12.1957 16.9529 12.0001C16.9529 11.8045 16.8765 11.6166 16.74 11.4766L8.96701 3.50106C8.90342 3.43575 8.82738 3.38383 8.74339 3.34839C8.65941 3.31294 8.56917 3.29468 8.47801 3.29468C8.38685 3.29468 8.29661 3.31294 8.21263 3.34839C8.12864 3.38383 8.0526 3.43575 7.98901 3.50106V3.49956Z"
        fill="#000"
      />
    </svg>
  </div>

  <div
    class="controls tw-mt-8"
    v-if="variant === 'v2'"
    :class="{ '!tw-hidden': !canPaginateNext && !canPaginateBack }"
  >
    <div
      class="slide-left-arrow w-slider-arrow-left !tw-z-20"
      :isDisabled="!canPaginateBack"
      @click="() => swiper.slidePrev()"
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M16.011 3.49956C16.1418 3.6335 16.2151 3.81332 16.2151 4.00056C16.2151 4.18781 16.1418 4.36762 16.011 4.50156L8.70149 12.0001L16.011 19.4971C16.1418 19.631 16.2151 19.8108 16.2151 19.9981C16.2151 20.1853 16.1418 20.3651 16.011 20.4991C15.9474 20.5644 15.8714 20.6163 15.7874 20.6517C15.7034 20.6872 15.6132 20.7054 15.522 20.7054C15.4308 20.7054 15.3406 20.6872 15.2566 20.6517C15.1726 20.6163 15.0966 20.5644 15.033 20.4991L7.25999 12.5236C7.12347 12.3835 7.04706 12.1957 7.04706 12.0001C7.04706 11.8045 7.12347 11.6166 7.25999 11.4766L15.033 3.50106C15.0966 3.43575 15.1726 3.38383 15.2566 3.34839C15.3406 3.31294 15.4308 3.29468 15.522 3.29468C15.6132 3.29468 15.7034 3.31294 15.7874 3.34839C15.8714 3.38383 15.9474 3.43575 16.011 3.50106V3.49956Z"
          fill="#6EADAB"
        />
      </svg>
      <div class="slide-circle slider-circle-transform"></div>
    </div>
    <div
      class="slide-right-arrow w-slider-arrow-right !tw-z-20"
      :isDisabled="!canPaginateNext"
      @click="() => swiper.slideNext()"
    >
      <svg
        width="24"
        height="24"
        viewBox="0 0 24 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M7.98901 3.49956C7.85817 3.6335 7.78491 3.81332 7.78491 4.00056C7.78491 4.18781 7.85817 4.36762 7.98901 4.50156L15.2985 12.0001L7.98901 19.4971C7.85817 19.631 7.78491 19.8108 7.78491 19.9981C7.78491 20.1853 7.85817 20.3651 7.98901 20.4991C8.0526 20.5644 8.12864 20.6163 8.21263 20.6517C8.29661 20.6872 8.38685 20.7054 8.47801 20.7054C8.56917 20.7054 8.65941 20.6872 8.74339 20.6517C8.82738 20.6163 8.90342 20.5644 8.96701 20.4991L16.74 12.5236C16.8765 12.3835 16.9529 12.1957 16.9529 12.0001C16.9529 11.8045 16.8765 11.6166 16.74 11.4766L8.96701 3.50106C8.90342 3.43575 8.82738 3.38383 8.74339 3.34839C8.65941 3.31294 8.56917 3.29468 8.47801 3.29468C8.38685 3.29468 8.29661 3.31294 8.21263 3.34839C8.12864 3.38383 8.0526 3.43575 7.98901 3.50106V3.49956Z"
          fill="#6EADAB"
        />
      </svg>
      <div class="slide-circle right"></div>
    </div>
  </div>

  <div
    v-if="variant === 'v3'"
    data-w-id="080a9ca2-7a2d-2ca3-3b22-f42b27569daf"
    class="v3-left-arrow !tw-z-20"
    :class="{ '!tw-hidden': !canPaginateNext && !canPaginateBack }"
    :isDisabled="!canPaginateBack"
    @click="() => swiper.slidePrev()"
  >
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M6.707 2.29303C6.51947 2.10556 6.26516 2.00024 6 2.00024C5.73484 2.00024 5.48053 2.10556 5.293 2.29303L0.793 6.79303C0.605529 6.98056 0.500214 7.23487 0.500214 7.50003C0.500214 7.76519 0.605529 8.0195 0.793 8.20703L5.293 12.707C5.4816 12.8892 5.7342 12.99 5.9964 12.9877C6.2586 12.9854 6.50941 12.8803 6.69482 12.6948C6.88023 12.5094 6.9854 12.2586 6.98767 11.9964C6.98995 11.7342 6.88916 11.4816 6.707 11.293L4 8.50003H13.5C13.7652 8.50003 14.0196 8.39467 14.2071 8.20714C14.3946 8.0196 14.5 7.76525 14.5 7.50003C14.5 7.23481 14.3946 6.98046 14.2071 6.79292C14.0196 6.60539 13.7652 6.50003 13.5 6.50003H4L6.707 3.70703C6.89447 3.5195 6.99979 3.26519 6.99979 3.00003C6.99979 2.73487 6.89447 2.48056 6.707 2.29303Z"
        fill="black"
      />
    </svg>
  </div>
  <div
    v-if="variant === 'v3'"
    class="v3-right-arrow !tw-z-20"
    :isDisabled="!canPaginateNext"
    :class="{ '!tw-hidden': !canPaginateNext && !canPaginateBack }"
    @click="() => swiper.slideNext()"
  >
    <svg
      width="15"
      height="15"
      viewBox="0 0 15 15"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M8.293 2.29303C8.48053 2.10556 8.73484 2.00024 9 2.00024C9.26516 2.00024 9.51947 2.10556 9.707 2.29303L14.207 6.79303C14.3945 6.98056 14.4998 7.23487 14.4998 7.50003C14.4998 7.76519 14.3945 8.0195 14.207 8.20703L9.707 12.707C9.5184 12.8892 9.2658 12.99 9.0036 12.9877C8.7414 12.9854 8.49059 12.8803 8.30518 12.6948C8.11977 12.5094 8.0146 12.2586 8.01233 11.9964C8.01005 11.7342 8.11084 11.4816 8.293 11.293L11 8.50003H1.5C1.23478 8.50003 0.98043 8.39467 0.792893 8.20714C0.605357 8.0196 0.5 7.76525 0.5 7.50003C0.5 7.23481 0.605357 6.98046 0.792893 6.79292C0.98043 6.60539 1.23478 6.50003 1.5 6.50003H11L8.293 3.70703C8.10553 3.5195 8.00021 3.26519 8.00021 3.00003C8.00021 2.73487 8.10553 2.48056 8.293 2.29303Z"
        fill="black"
      />
    </svg>
  </div>
</template>

<script lang="ts">
export default defineNuxtComponent({
  name: 'LibrarySliderPagination',

  props: {
    swiper: {
      required: true,
    },
    variant: {
      type: String,
      default: 'v1',
    },

    breakpoint: {
      type: Number,
      default: 768,
    },

    canPaginateBack: {
      type: Boolean,
      default: true,
    },

    canPaginateNext: {
      type: Boolean,
      default: true,
    },
  },
});
</script>

<style>
/* V1 */

.nav-left-arrow,
.nav-right-arrow {
  padding: 0 10px;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s;
}

.nav-left-arrow {
  left: -20px;
}
.nav-right-arrow {
  right: -20px;
}

.nav-left-arrow[isDisabled='true'],
.nav-right-arrow[isDisabled='true'] {
  cursor: default;
}

.nav-left-arrow svg,
.nav-right-arrow svg {
  width: 32px;
  height: 32px;
}

.nav-left-arrow:not([isDisabled='true']):hover svg path,
.nav-right-arrow:not([isDisabled='true']):hover svg path {
  fill: var(--c-primary);
}

/* V2 */

.controls {
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  display: flex;
}

.slide-left-arrow {
  border-top-left-radius: 100px;
  border-bottom-left-radius: 100px;
}

.slide-right-arrow {
  border-top-right-radius: 100px;
  border-bottom-right-radius: 100px;
}

.slide-left-arrow,
.slide-right-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 14px 28px;
  background-color: white;
  cursor: pointer;
  position: relative;
  transition: all 0.3s;
}

.latest-properties-sidebar .slide-left-arrow,
.latest-properties-sidebar .slide-right-arrow {
  background-color: #f7fbf3;
}

.slide-left-arrow[isDisabled='true'],
.slide-right-arrow[isDisabled='true'] {
  cursor: default;
}
.slide-left-arrow:not([isDisabled='true']):hover,
.slide-right-arrow:not([isDisabled='true']):hover {
  background-color: var(--c-primary) !important;
}

.slide-left-arrow svg,
.slide-right-arrow svg {
  width: 32px;
  height: 32px;
}

.slide-left-arrow:not([isDisabled='true']):hover svg path,
.slide-right-arrow:not([isDisabled='true']):hover svg path {
  fill: white;
}

.slide-left-arrow::after,
.slide-right-arrow::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 2px;
  height: 100%;
  background-color: #969696;
  transform: translate(-50%, -50%);
}

.slide-left-arrow::after {
  left: 100%;
}

.slide-right-arrow::after {
  left: 0;
}

/* V3 */

.v3-left-arrow,
.v3-right-arrow {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 13px;
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  transition: all 0.3s;
  box-shadow: var(--c-box_shadow);
}

@media screen and (min-width: 1300px) {
  .v3-left-arrow,
  .v3-right-arrow {
    padding: 16px;
  }
}

.v3-left-arrow {
  transform: translatex(-60%);
  left: 10px;
}

.v3-right-arrow {
  transform: translatex(60%);
  right: 10px;
}

.v3-left-arrow[isDisabled='true'],
.v3-right-arrow[isDisabled='true'] {
  cursor: default;
}

.v3-left-arrow:not([isDisabled='true']):hover,
.v3-right-arrow:not([isDisabled='true']):hover {
  background-color: var(--c-secondary);
}

.v3-left-arrow:not([isDisabled='true']):hover svg path,
.v3-right-arrow:not([isDisabled='true']):hover svg path {
  fill: white;
}
</style>
